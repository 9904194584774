import * as React from "react";
import * as styles from "./Footer.module.css";
import youTubeIcon from "../../images/youtube.png";
import twitterIcon from "../../images/twitter.png";

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerBlurbContainer}>
        <span>
          Made by
          <a
            href="https://sophiali.dev/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.footerWebsiteSocial}
          >
            Sophia Li
          </a>
        </span>
      </div>

      <div className={styles.footerSocialsContainer}>
        <a
          href="https://twitter.com/sophia_wyl"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.footerSocials}
        >
          <span>Twitter</span>
          <img src={twitterIcon} alt="Twitter logo" />
        </a>

        <a
          href="https://www.youtube.com/c/sophiali"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.footerSocials}
        >
          <span>YouTube</span> <img src={youTubeIcon} alt="YouTube logo" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
