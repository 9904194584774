import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./Header.module.css";

const Header = () => {
  return (
    <header className={styles.globalHeader}>
      <Link className={styles.headerLink} to="/">
        <span>Sophia</span>
      </Link>
      <Link className={styles.headerLink} to="/blog">
        <span>Blog</span>
      </Link>
      {/* <Link className={styles.headerLink} to="/food">
        <span>Food</span>
      </Link> */}
      {/* <Link className={styles.headerLink} to="/adventures">
        <span>Adventures</span>
      </Link> */}
      <Link className={styles.headerLink} to="/about">
        <span>About</span>
      </Link>
    </header>
  );
};

export default Header;
