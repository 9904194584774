import * as React from "react";
import * as styles from "./Layout.module.css";

import Footer from "../Footer";
import Header from "../Header";

const Layout = ({ children }) => {
  return (
    <div className={styles.globalWrapper}>
      <div>
        <Header />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
